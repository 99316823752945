<template>
    <div
        class="d-flex flex-row align-center"
    >
        <v-btn
            icon
            class="hidden-xs-only white--text"
            @click.stop="$router.go(-1)"
        >
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="white--text">{{ toolbarTitle }}</span>
    </div>
</template>

<script>
import { isEmptyObj } from "../utils/utils";
import { mapState } from "vuex";

export default {

    data: () => ({
    }),

    computed: {
        ...mapState({
            currentFarm: state => state.farmModule.currentFarm,
            currentStore: state => state.storeModule.currentStore,

        }),

        toolbarTitle() {
            if (this.$route.name === 'Multi') {
                return "Multi"
            }
            if (!isEmptyObj(this.currentStore)) {
                console.log(this.currentFarm)
                return (
                    this.currentFarm.Consorzio +
                    " - " +
                    this.currentStore.AliasCliente +
                    " - " +
                    this.currentStore.IndirizzoInsegna +
                    " - " +
                    this.currentStore.ComuneInsegna
                );
            }
            return this.currentFarm.Consorzio;
        }
    }
};
</script>

<style></style>
